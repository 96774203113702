import * as React from 'react';
import { LanguagesWrapper, LanguagesSelectElement, LanguagesSelect, LanguagesOption } from './styled';
import { Link } from 'react-router-dom';

import {FormattedMessage} from 'react-intl';
import livstickApp from '../../models/livstick';

const Languages: React.FunctionComponent = () => {
  const language = navigator.language.split(/[-_]/)[0];
  return <LanguagesWrapper>
    {/* <LanguagesSelectElement>
      <LanguagesSelect onChange={e => {
        livstickApp.changeLang(e.currentTarget.value);
      }}>
        <LanguagesOption selected={language == 'fr'} value="fr">Fr</LanguagesOption>
        <LanguagesOption selected={language == 'en'} value="en">En</LanguagesOption>
        <LanguagesOption selected={language == 'es'} value="es">Es</LanguagesOption>
        <LanguagesOption selected={language == 'it'} value="it">It</LanguagesOption>
        <LanguagesOption selected={language == 'de'} value="de">De</LanguagesOption>
        <LanguagesOption selected={language == 'zh'} value="zh">Zh</LanguagesOption>
        <LanguagesOption selected={language == 'ja'} value="ja">Jp</LanguagesOption>
      </LanguagesSelect>
    </LanguagesSelectElement> */}
  </LanguagesWrapper>;
}

export default Languages;
