import styled, { css } from "styled-components";
import icoInfo from './images/ico-info.svg';
import imageFish from './images/fish.png';
import { colors } from './../../variables';

export const Catchline = styled.h1`
  position: relative;
  font-size: 19px;
  font-weight: bold;
  color: #0054A5;
  text-transform: uppercase;
  font-style: normal;
  
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    margin-top: 30px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
  }
`;

export const LandingWrapper = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
  margin-top: 150px;
  z-index: 30;
  left: 50%;
  margin-left: -200px;
  @media (max-width: 991px){
    position: relative;
    margin-top: 80px;
  }
  @media (max-width: 767px){
    margin-top: 150px;
    width: 90%;
    left: 50%;
    margin-left: -45%;
  }
  @media (max-width: 468px){
    position: relative;
    margin-top: 150px;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ccc;
`;

export const LandingContent = styled.div`
  padding: 20px 40px;
  color: #3A5179;
  text-align: center;
`;

export const LandingTitle = styled.div`
  font-weight: 400;
  margin-top: 20px;
  font-size: 14px;
  color: #3A5179;
`;

export const LandingMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  font-family: 'VAG Rounded';
  color: #0054A5;
`;

export const LandingFormContainer = styled.div`
  
`;

export const LandingForm = styled.div`
  padding: 40px;
  color: #000;
  padding-bottom: 20px;
  @media (max-width: 991px){
    button{
      width: 100%;
    }
  }
  @media (max-width: 767px){
      padding: 20px;
    }
  h3{
    margin-bottom: 20px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
    button{ 
      margin: 0px;
      margin-top: 10px;
      width: 100%;
      text-align: center;
      justify-content: center;
      @media (max-width: 767px){
        flex: inherit;
        font-size: 14px;
        line-height: inherit;
      }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;



export const InputField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 13px 20px;
  border-radius: 15px;
  color: #404040;
  width: 100%;
  display: block;
  position: relative;
  font-size: 14px;
  border: 3px solid #B4B4B4;
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  @media (max-width: 767px){
    padding: 10px 20px;
  }
  &:focus{
    outline: inherit;
  }
`;
export const InputInfoIcon = styled.div<{active?: boolean}>`
  position: absolute;
  right: 20px;
  top: 13px;
  background-image: url(${icoInfo});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
  @media (max-width: 767px){
    top: 8px;
  }
  ${props => props.active && css`
    opacity: 1;
  `}
`;
export const InputInfo = styled.div<{active?: boolean}>`
  position: absolute;
  right: 0px;
  bottom: -70px;
  background-color: #B4B4B4;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  font-size: 11px;
  font-style: italic;
  color: #fff;
  z-index: 9999;
  &:after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #B4B4B4;
    right: 17px;
    margin-left: 15px;
  }
  display: none;
  ${props => props.active && css`
    display: block;
  `}
`;

export const LandingImage = styled.img`
  background-image: url(${imageFish});
  background-size: 50px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  border: 0px;
`;