import { colors } from './../../variables';
import styled from "styled-components";

export const HeaderWrapper = styled.header`
  height: 70px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  border-bottom: 2px solid #0054A5;
  display: flex;
  @media (max-width: 991px){
    text-align: center;
    position: relative;
    display: block;
    padding-left: 0px;
    height: auto;
    padding-top: 15px;
  }
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
  position: relative;
  left: 0px;
  top: 0px;
  color: #000;
  text-transform: uppercase;
  font-style: italic;
  margin-right: 20px;
  @media (max-width: 991px){
    width: 110px;
    position: relative;
    top: inherit;
    z-index: 2;
    left: inherit;
    text-align: center;
    margin: 0 auto;
    top: 0px;
  }
`;

export const Catchline = styled.h1`
  position: relative;
  font-size: 22px;
  font-weight: normal;
  color: #EC008B;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
  }
`;