import axios from 'axios';
import manifest from '../manifest';

import { observable } from 'mobx';
import moment from 'moment';

export const language = observable({
  codeInt: 1,
  code: 'fr'
});

export const progression = observable({
  value: 0,
});

class LivstickApp {
  currentCountry: string;

  code:string;
  answer: boolean;
  videoFile: Blob;
  message: string;
  timestamp: string;
  planification: Date;
  planificationTime: Boolean;
  email: string;
  phone: string;
  senderEmail: string;

  firstname: string;
  lastname: string;
  country: string;
  birth: string;
  checkbox2: number;

  UTM: boolean;
  UTM_source: string;
  UTM_medium: string;
  utm_campaign: string;

  onChangeLang: Function[];

  inProgress: boolean = false;
  uploaded: boolean = false;
  imageFile: any;

  

  changeLang(lang) {
    var codes = {
      'fr': 1,
      'en': 2,
      'en-US': 2,
      'en-UK': 2,
      'es': 4,
      'de': 9,
      'it': 5,
      'zh': 12,
      'ja': 13,
    }
    language.codeInt = codes[lang] || 2;
    language.code = lang || 'en';
    this.onChangeLang.forEach(func => func(lang));
  }

  async getCountry() {
    let _res = await axios({
      method: 'GET',
      url: `/getCountry`,
    });

    this.currentCountry = _res.data.iso_code;
    return _res.data;
  }

  async triage() {
    let _res = await axios({
      method: 'GET',
      url: `/triage/?code=${this.code}${this.answer ? '&answer=1': ''}`,
    });
    return _res.data;
  }

  async pull() {
    let _res = await axios({
      method: 'GET',
      url: `/triage/?code=${this.code}${this.answer ? '&answer=1': ''}`,
    });
    return _res.data;
  }

  async pullNoLog() {
    let _res = await axios({
      method: 'GET',
      url: `/triage/?code=${this.code}${this.answer ? '&answer=1': ''}&nolog=1`,
    });
    return _res.data;
  }

  async scheduleMessage() {
    let _res = await axios({
      method: 'GET',
      url: `/scheduleMessage/?code=${this.code}&reseller=${manifest.RESSELER}&email=${this.senderEmail}&unit=minute&delay=${this.planificationTime}&email_type=RLV_EMAIL_INIT&sms_type=RES_SMS_NOTIF&mobile=${this.phone}`,
    });
    return _res.data;
  }

  async pushMedia() {
    this.inProgress = true;

    var fd = new FormData();
    var time = new Date().getTime();

    fd.append('timestamp', time.toString());
    fd.append('reseller', manifest.RESSELER.toString());
    fd.append('type', 'VIDEO');
    fd.append('fname', 'video.webm');
    fd.append('file', this.videoFile);

    if (manifest.CODE_AUTO && this.answer != true){
      let _res = await axios({
        method: 'GET',
        url: `/checkCode/?code=${manifest.CODE_AUTO_CODE}`,
      });
      this.code = _res.data.code;
    }

    fd.append('code', this.code);

    var __r = await axios({
      method: 'GET',
      url: `/storeData/?code=${this.code}&reseller=${manifest.RESSELER.toString()}&receiverEmail=${this.email}&senderEmail=${this.senderEmail}&receiverMobile=${this.phone}&senderFirstname=${this.firstname}&senderLastname=${this.lastname}&country=${this.country}&language=${navigator.language}&birth=${this.birth}${this.checkbox2 ? '&checkbox2=1' : '&checkbox2=0' }${this.UTM ? `&utmSource=${this.UTM_source}&utmMedium=${this.UTM_medium}&utmCampaign=${this.utm_campaign}`: '' }${this.answer ? '&answer=1': ''}`,
    });

    var response = await axios({
      method: 'POST',
      url: "/liv3/upload_file.php",
      data: fd,
      headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 90) / progressEvent.total)
        console.log(percentCompleted, progressEvent);
        progression.value = percentCompleted;
      }
    })

    //handle success
    console.log(response);
    var url = response.data.replace("https://fm2x.me", "");
    
    var fd2 = new FormData();
    fd2.append('code', this.code);
    fd2.append('quote', this.message);
    if (this.senderEmail) fd2.append('email', this.senderEmail);
    if (this.phone != null) {
      fd2.append('mobile', this.phone );
      fd2.append('sms_type', 'RES_SMS_NOTIF');
    }
    fd2.append('type', 'VIDEO');
    fd2.append('reseller', manifest.RESSELER.toString());
    fd2.append('newsletter', '0');
    fd2.append('optin', '1');
    fd2.append('ordtype', manifest.VM ? '2' : '1');
    if (this.planification) {

      const diffTime = Math.abs(this.planification.valueOf() - Date.now());
      const diffMinutes = Math.ceil(diffTime / (60 * 1000)); 
      fd2.append('delay', diffMinutes.toString());
      fd2.append('unit', 'minute');
    } else {
      fd2.append('delay', '1');
      fd2.append('unit', 'minute');
    }
    

    if (this.answer) {
      fd2.append('answer', '1');
    }else{
      fd2.append('answer', '0');
    }

    await axios({
      method: 'POST',
      url: url,
      data: fd2
    });

    this.uploaded = true;
    this.inProgress = false;
  }

  getMedia() {
    axios({
      method: 'GET',
      url: `/liv3/pull_text.php?code=${this.code}`,
    });
  }
}

let livstickApp = new LivstickApp();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (urlParams.get('utm_source') != null || urlParams.get('utm_medium') != null || urlParams.get('utm_campaign') != null) {
  livstickApp.UTM = true;
  livstickApp.UTM_source = urlParams.get('utm_source');
  livstickApp.UTM_medium = urlParams.get('utm_medium');
  livstickApp.utm_campaign = urlParams.get('utm_campaign');
}

export default livstickApp;



