import * as React from 'react';
import { Fish, HelpWrapper, HelpList, HelpListItem, HelpContainer, HelpClose, HelpContent, HelpOpenMobile } from './styled';
import { Link } from 'react-router-dom';
import imageFish from './images/fish.jpg';
import {FormattedMessage, useIntl} from 'react-intl';

const Help: React.FunctionComponent = () => {
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [helpActive, setHelpActive] = React.useState<boolean>(false);

  const intl = useIntl();

  const openTab = (index: number) => {
    setHelpActive(true);
    setTabIndex(index);
  }

  const closeTab = () => {
    setHelpActive(false);
  }

  return <HelpWrapper>
    <HelpList active={helpActive == true}>
      <HelpListItem active={tabIndex == 0}><a onClick={() => openTab(0)}><FormattedMessage id="help_txt_menu_help"/></a></HelpListItem>
      <HelpListItem active={tabIndex == 1}><a onClick={() => openTab(1)}><FormattedMessage id="help_txt_menu_about"/></a></HelpListItem>
      <HelpListItem active={tabIndex == 2}><a onClick={() => openTab(2)}><FormattedMessage id="help_txt_menu_contact"/></a></HelpListItem>
    </HelpList>
    <Fish>
      <img src={imageFish} />
    </Fish>
    <HelpOpenMobile onClick={() => openTab(0)} active={helpActive == true} />
    <HelpContainer active={helpActive == true}>
        <HelpContent>
        {tabIndex == 0 && <div>
          <h1><FormattedMessage id="help_txt_menu_help"/></h1>
          <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "help_txt_text_help"})}}></span>
        </div>}

        {tabIndex == 1 && <div>
          <h1><FormattedMessage id="help_txt_menu_about"/></h1>
          <FormattedMessage id="help_txt_text_about"/>
        </div>}

        {tabIndex == 2 && <div>
          <h1><FormattedMessage id="help_txt_menu_contact"/></h1>
          <FormattedMessage id="help_txt_text_contact"/>
        </div>}
        </HelpContent>
        <HelpClose onClick={() => closeTab()}></HelpClose>
    </HelpContainer>
  </HelpWrapper>;
}

export default Help;
